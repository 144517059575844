import React from 'react';
import './Footer.css';

const Footer = () => (
    <div className="footer">
        Made with ❤️ by the ManageInvite team.
    </div>
);

export default Footer;
